import { useEffect, useState } from "react";
import axios from "axios";
import { io } from "socket.io-client";
import ItemList from "./components/ItemList.js";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import "./styles/style.css";
import "./styles/App.css";
import "./styles/strap.css";
import {LoadScreen2} from "./components/LoadScreen.js";
import User from "./models/user.ts";



function App() {
  const [user, setUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const [site, setSite] = useState("en");
  const getData = (lang) => {
    LoadScreen2("Fetching Website Data", true);
     axios
      .get(`${process.env.REACT_APP_API_URL}/my-profile`, {
        params: { lang: lang},
      })

      .then(async (res) => {
        setUser(new User(res.data[0]));
        if (lang === "cn") {
          document.querySelector(".link.en").classList.toggle("active", false);
          document.querySelector(".link.cn").classList.toggle("active", true);
        } else {
          document.querySelector(".link.en").classList.toggle("active", true);
          document.querySelector(".link.cn").classList.toggle("active", false);
        }
        LoadScreen2("Getting Website Data", false);
        setSite(lang);
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    getData("en");
    initSocket();
  }, []);

function initSocket() {

    const socket = io(process.env.REACT_APP_SERVER_URL, {
      extraHeaders: {
        "ngrok-skip-browser-warning": "1",
      },
    });
    socket.on("connect", () => {


      axios
        .get(`${process.env.REACT_APP_API_URL}/connect`, {
          params: {
            ip: '1235',
          },
        })
        .then((res) => {

          console.log(res.data);
        }).catch(err=>{
          console.log("Error getting IP")
        })

    });
    socket.on("message", (data) => {
      MakeMessageHtml(data);
    });
    socket.on("setuser", (data) => {
      console.log("setting user");
      window.localStorage.setItem("email", data.email);
      window.localStorage.setItem("roomID", data.roomId);
      console.log(data);
      axios
        .get(`${process.env.REACT_APP_API_URL}/history`, {
          params: { roomId: data.roomId},
        })
        .then((res) => {
          res.data.forEach((data) => {
            MakeMessageHtml(data);
          });


        })
        .catch((err) => {
          console.log(err);
        });
      
    });
    socket.on("admin-joined-room", () => {
      const chatForm = document.getElementById("chat-form");
      const messages = document.getElementById("messages");
      const li = document.createElement("li");
      const p = document.createElement("p");
      p.textContent = `Aman has joined you!`;
      li.appendChild(p);
      messages.appendChild(li);
      chatForm.reset();
      
      messages.scrollTop = messages.scrollHeight;
    });
    setSocket(socket);
  }
  return (
    <>
      <Header getData={getData} />
      {user ? (
        <ItemList
          itemInfo={{
            user: user,
            socket: socket,
            site: site,
          }}
        />
      ) : (
        ""
      )}
      <Footer site={site}/>

    </>
  );
}

export default App;

function MakeMessageHtml(data) {
  const chatForm = document.getElementById("chat-form");
  const messages = document.getElementById("messages");
  const li = document.createElement("li");
  const p = document.createElement("p");
  p.textContent = data.message;
  li.className = data.sender === "admin" ? "msg" : "msg i";
  li.appendChild(p);
  messages.appendChild(li);
  chatForm.reset();
  messages.scrollTop = messages.scrollHeight;
}
function triggerProtection() {
  const overlay = document.createElement("div");
  overlay.style.position = "fixed";
  overlay.style.width = "100dvw";
  overlay.style.height = "100dvh";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.background = "black";
  overlay.style.zIndex = "500";
  overlay.innerHTML = "<h1>Please close developer tools</h1>";
  document.getElementById("root").remove();
  document.body.appendChild(overlay);
  console.log = console.warn = console.error = () => {};
  debugger;
}
function detectDevTools() {
  const start = performance.now();
  debugger;
  const end = performance.now();
  if (end - start > 100) {
    console.log("Developer tools opened");
    triggerProtection();
  }
}
