import { v4 as uuidv4 } from "uuid";
import { PreviewCard } from "./Projects/Projects";
import Message from "../models/message.ts";
import { useEffect } from "react";
import axios from "axios";

import '../styles/style.css';
import { LoadScreen2 } from "./LoadScreen";

let interval
export function Item({ itemInfo, type }) {
  let item = <></>;

  switch (type) {
    case "aboutme":
      item = <AboutMe itemInfo={itemInfo} />;
      break;
    case "projects":
      item = <Projects itemInfo={itemInfo} />;
      break;
    case "experience":
      item = <Experience itemInfo={itemInfo} />;
      break;
    case "skills":
      item = <Skills itemInfo={itemInfo} />;
      break;
    case "chatbox":
      item = <ChatBox itemInfo={itemInfo} />;
      break;
    default:
      break;
  }
  return item;
}
function AboutMe({ itemInfo }) {
  const text = "> Software engineering graduate with a strong foundation in computer science, currently pursuing a master’s degree in Computer Science. Seeking a position to leverage my technical expertise and problem-solving skills to enhance customer satisfaction and operational efficiency.";
  const socialLinks = [
    {
      name: "linkedin",
      link: "https://www.linkedin.com/in/amantes30",
      icon: "/linkedin.svg"
    },
    {
      name: "github",
      link: "https://github.com/amantes30",
      icon: "https://amantes30.github.io/icons/SVG/github.svg"
    },
    {
      name: "unity",
      link: "https://learn.unity.com/u/6041e681edbc2a001f6e3530?tab=profile",
      icon: "https://amantes30.github.io/icons/SVG/unity.svg"
    },
    {
      name: "microsoftlearn",
      link: "https://learn.microsoft.com/en-us/users/amanuel-0733/transcript/v0g8wa135rrkg6j",
      icon: "https://learn.microsoft.com/favicon.ico"
    }
  ]
  useEffect(() => {
    writeAnim(text, document.getElementById('intro'), 10);
  }, []);
  return (
    <div className="df w100" style={{ animationTimeline: "view(99% 1%)" }}>
      <div className="pfp w100" style={{ animationTimeline: "view(10% 90%)" }}>
        <img alt="pfp" src="/image/pfp.png" />
      </div>

      <h1 className="g-c-text" id="name">
        {itemInfo.user.idinfo[0].firstname}
      </h1>
      <ul className='social-links df'>
        {
          socialLinks.map((link) => {
            return (
              <>
                <li key={uuidv4()}>
                  <a href={link.link} >
                    <img
                      alt={link.name}
                      src={link.icon}
                    />
                  </a>
                </li>
                |
              </>
            )
          })}

        <li>
          <a className='p-btn' href="/SEResume.pdf" download={'SEResume.pdf'}>
            <img style={{ width: '20px', height: '16px' }} src="/image/download-icon.svg" alt="download" />
            <span>Resume</span>
          </a>
        </li>
      </ul>
      <h3
        className="g-c-text"
        id="degree"
        onMouseEnter={() => {
          document.getElementById("degree-img").style.scale = "1";
        }}
        onMouseLeave={() => {
          document.getElementById("degree-img").style.scale = "0";
        }}
      >
        {itemInfo.user.idinfo[0].profession}
        <div id="degree-img">
          <img className="w100" src="/image/degree.png" alt={'degree'} />
        </div>
      </h3>

      <section className="console">
        <span>{">_ "}</span>
        <p id="intro"></p>
      </section>
    </div>
  );
}

function ChatBox({ itemInfo }) {
  const socket = itemInfo.socket;
  return (
    <>
      <div className="chat closed">
        <div className="chat-header">
          <img alt='logo' className="chat-logo"
            src="https://amantes30.github.io/icons/SVG/logo(new).svg"></img>

          <button className="chat-close" onClick={toggleChat}>
            <img src="/close.svg" />
          </button>
        </div>
        <div className="chat-body">


          <ul id="messages">
            <li></li>
          </ul>


          <form

            id="chat-form"
            style={{ display: "none" }}
            onSubmit={(e) => {
              e.preventDefault();
              if (!socket.connected) {
                alert("Please input your email first");
                return;
              }
              const msg = {
                message: e.target.message.value,
                sender: window.localStorage.getItem("email"),
                timeStamp: new Date().toISOString(),
                roomId: window.localStorage.getItem("roomID"),
              };
              console.log("yes")
              socket.emit("message", new Message(msg));
            }}
          >
            {itemInfo.site === "en" ? (
              <>
                <input
                  type="text"
                  placeholder="say what?"
                  className="form-control"
                  name="message"
                  autoComplete="off"
                />

                <button type="submit" id="send-btn">
                  Send
                </button>
              </>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="说什么？"
                  className="form-control"
                  name="message"
                  autoComplete="off"
                />
                <button type="submit" id="send-btn">
                  发送
                </button>
              </>
            )}
          </form>


          <form
            id="hidden-chat-form"
            onSubmit={async (e) => {
              e.preventDefault();

              const input = document.getElementById("email-input");
              input.classList.toggle("loading");
              input.readOnly = true;
              let message = "Connecting chat server"
              LoadScreen2(message, true);

              window.localStorage.setItem("email", e.target.email.value);

              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/login?email=${e.target.email.value}`
                )
                .then((res) => {
                  console.log(res.data);
                  if (socket.connected) {


                    // welcome message
                    const messages = document.getElementById("messages");
                    const li = document.createElement("li");
                    const p = document.createElement("p");
                    socket.emit("setuser", res.data);
                    messages.innerHTML = '';
                    writeAnim(`Welcome to the chat. Please give me a few seconds to connect to the server.\n Feel free to put in your inquiry, I can read it when I'm online.`,
                      p, 5)


                    //p.textContent = `Welcome to the chat. Please give me a few seconds to connect to the server.\n Feel free to put in your inquiry, I can read it when I'm online.`;
                    li.className = "msg";
                    li.appendChild(p);
                    messages.appendChild(li);
                    e.target.reset();
                    e.target.style = "display:none";
                    document.getElementById('chat-form').style = "display: flex"

                    LoadScreen2(message, false);

                  } else {
                    alert(
                      "Server is down, This feature is not available at the moment"
                    );
                  }
                })
                .catch((err) => {
                  alert("Error connecting to the server");
                });
            }}
          >
            {itemInfo.site === "en" ? (
              <input
                type="email"
                placeholder="Your Email"
                className="form-control"
                name="email"
                autoComplete="off"
                required
                id="email-input"
              />
            ) : (
              <input
                type="email"
                placeholder="电子邮件"
                className="form-control"
                name="email"
                autoComplete="off"
                required
                id="email-input"
              />
            )}

            <button type="submit" id="send-btn">
              set
            </button>
          </form>

        </div>
      </div>

    </>
  );
}
function Experience({ itemInfo }) {
  var listt = [];
  itemInfo.user.experiences.map((experience) => {
    return listt.push(
      <li className="df" key={uuidv4()}>
        <img src={experience.logoUrl} />
        <div>
          <a
            style={{ color: "white", textDecoration: "none" }}
            href={"https://" + experience.link}
          >
            <h3>{experience.organization}</h3>
          </a>
          <p>{experience["job"]} <span style={{ display: "block" }}>{experience.description}</span></p>

        </div>
      </li>
    );
  });

  return (
    <div className="experience-item df w100">
      {itemInfo.site === "en" ? (
        <h2 className="title g-c-text">Internship</h2>
      ) : (
        <h2 className="title g-c-text">实习</h2>
      )}
      <hr />
      <ul className="df">{listt}</ul>
    </div>
  );
}

function Skills({ itemInfo }) {
  var list = [];
  var list2 = [];
  var list3 = [];
  itemInfo.user.skills.forEach((skill) => {
    switch (skill.type) {
      case "lang":
        list.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icon w100 shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      case "framework":
        list2.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icon w100 shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      case "tool":
        list3.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icon w100 shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      default:
        break;
    }
  });
  return (
    <div className="skills df w100" style={{ animationTimeline: "view(70% 10%)" }}>
      <div
        className="skills-animoji"
        style={{ animationTimeline: "view(70% 20%)" }}
      >
        <img className="w100" src="/image/animoji.png"></img>
      </div>

      <section className="skill-wrapper df">
        {itemInfo.site === "en" ? (
          <h2 className="g-c-text">Programming languages</h2>
        ) : (
          <h2 className="g-c-text">编程语言</h2>
        )}

        <ul className="skill-item-list df scroll-anim">
          {list}
          {list}
        </ul>
      </section>

      <section className="skill-wrapper df">
        <h2 className="g-c-text">Frameworks</h2>
        <ul className="skill-item-list df">{list2}</ul>
      </section>

      <section className="skill-wrapper df">
        {itemInfo.site === "en" ? (
          <h2 className="g-c-text">Tools</h2>
        ) : (
          <h2 className="g-c-text">工具</h2>
        )}

        <ul className="skill-item-list df scroll-anim">
          {list3}
          {list3}
        </ul>
      </section>

      {/* <p>3D modelling (3ds Max)</p> */}
    </div>
  );
}
function Projects({ itemInfo }) {

  const projects = [
    {
      title: "Photos",
      description:
        "This website integrates Unsplash's API to retrieve client's pictures and showcase in the portfolio",
      imagelink: "/image/yosias G.png",
      link: "https://yozz1.pages.dev",
    },
    {
      title: "Text-to-Image",
      description:
        "This website uses SD-XL 1.0-base Model Card to convert text to image",
      imagelink: "/image/text-to-img(thumb).jpg",
      link: "https://amantes30.github.io/text-to-image/",
    },
    {
      title: "Translator",
      description:
        "Translator website that uses Google's API to translate text",
      imagelink: "/image/translator.png",
      link: "https://translator-cj1.pages.dev",
    },

    {
      title: "Weather Site",
      description:
        "This website uses 7Timer API to retrieve 7 day weather forecast for different cities",
      imagelink: "/image/weathersite.png",
      link: "https://weather-112.pages.dev",
    },
    {
      title: "Terminal I/O",
      description:
        "HTML CSS&JS Terminal I/O",
      imagelink: "https://amantes30.github.io/Custom-Input/screenshot.png",
      link: "https://amantes30.github.io/Custom-Input/",
    }
  ];
  return (
    <div className="projects w100 " style={{ animationTimeline: "view(99% 1%)" }}>
      {itemInfo.site === "en" ? (
        <h2 className="title g-c-text">Projects</h2>
      ) : (
        <h2 className="title g-c-text">项目</h2>
      )}

      <div className="project-view df">
        <div className="active-item df">
          <h1 className="project-title">Projects Preview <span
          >Done</span>
          </h1>
          <p>
            Hover Over Projects to see more details
          </p>
          {/* <div>
            <img
              src="https://amantes30.github.io/icons/PNG/logo192.png" width={'30px'}>
            </img>
            <img
              src="https://amantes30.github.io/icons/SVG/css.svg" width={'30px'}>
            </img>
            <img
              src="https://amantes30.github.io/icons/SVG/vscode.svg" width={'30px'}>
            </img>
          </div> */}
          <a id="learn-more-btn" className='p-btn'>
            <span>Visit Site</span>
          </a>
        </div>
        <img id="active-img" className="w100" src="" alt="project-preview-image" />
        <section className={'project-list df'}>
          {projects.map((project, index) => {
            return (
              <PreviewCard
                title={project.title}
                description={project.description}
                link={project.link}
                imageLink={project.imagelink}
                key={index}
              />
            );
          })}


        </section>
      </div>
    </div>
  );
}

export function toggleChat(e) {
  e.preventDefault();
  const chat = document.querySelector(".chat");
  const chatboxToggle = document.querySelector(".chatbox-toggle");
  const messages = document.getElementById("messages");
  const inputField = document.getElementById("email-input");
  if (chat.classList.contains("closed")) {
    chatboxToggle.style = "display:none";
    chat.classList.toggle("closed", false);
    messages.scrollTop = messages.scrollHeight;
    inputField.focus();

    const p = document.createElement("p");

    const li = document.createElement("li");

    // writeAnim(
    //   'Please enter your email address, then I can try to connect with you',
    //   p,
    //   10
    // )
    li.className = "msg";
    li.appendChild(p);
    messages.appendChild(li);


  } else {
    chat.classList.toggle("closed", true);
    chatboxToggle.style = "display:flex, text-align:center";
  }

}

function getResume() {

}
export function writeAnim(text, element, time) {
  clearInterval(interval);
  const text_to_list = text.split("");
  let counter = 0;
  let inp = [];
  interval = setInterval(() => {
    if (counter < text_to_list.length) {
      inp.push(text_to_list[counter]);
      element.innerText = inp.join("");
      counter++;
    }
    else {
      clearInterval(interval);
    }
  }, time)
}