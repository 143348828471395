import {Item} from "./Item";
import "../styles/chat.css";

function ItemList({ itemInfo }) {
  return (
    <>
      <main>
        
        <Item itemInfo={itemInfo} type={"aboutme"} />
        <Item itemInfo={itemInfo} type={"projects"} />
        <Item itemInfo={itemInfo} type={"skills"} />
        <Item itemInfo={itemInfo} type={"experience"} />        
        <Item itemInfo={itemInfo} type={"chatbox"} />
      </main>
    </>
  );
}
export default ItemList;
